@import "../../common/StyleVariables/variables.scss";

#politica-privacidade-root {
  .service-channel-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: $privacidade-background-secondary-color;
    border-radius: $borderRadius;
    margin: 4vw;

    .bold-text {
      font-weight: $privacidade-font-weight-bold;
    }

    .service-channel-content {
      width: 45vw;
      padding: 0 4vw;
      align-content: center;

      .service-channel-subtitle {
        padding-top: 2vw;
        color: $privicidade-primary-text-color;
        font-style: italic;
        font-weight: $privacidade-font-weight;
        font-family: $privacidade-title-font-family;
        font-size: $privacidade-secondary-title-font-size;
        line-height: 1.5;
        margin-bottom: 0;
      }

      .privacy-channel-bottom {
        padding: 2vw 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
      }

      .highlight-green {
        color: $privacidade-primary-green;
        cursor: pointer;
        text-decoration: none;
      }

      .paragraph {
        font-family: $privacidade-paragraph-font-family;
        margin-bottom: 0;
        margin-top: $baseline;
        font-weight: $privacidade-font-weight;
        line-height: 1.5;
      }
    }

    .service-channel-image {
      width: 45vw;
      box-sizing: border-box;

      .service-channel-figure {
        display: block;
        margin: 0;

        .service-channel-picture {
          margin: 0;
        }

        .service-channel-image-src {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media (max-width: 512px) {
    .service-channel-section {
      margin-top: $baseline * 8;
      padding: $baseline * 2;

      .service-channel-content {
        width: 95%;

        .service-channel-subtitle {
          padding-bottom: $baseline;
          font-size: $privacidade-secondary-title-font-size-mobile-512;
        }
      }
    }

    .service-channel-image {
      display: none;
    }
  }

  @media (min-width: 513px) and (max-width: 1024px) {
    .service-channel-section {
      margin-top: $baseline * 8;
      padding: $baseline * 2;

      .service-channel-content {
        width: 95%;

        .service-channel-subtitle {
          padding-bottom: $baseline;
          font-size: $privacidade-secondary-title-font-size-mobile-1024;
        }
      }
    }

    .service-channel-image {
      display: none;
    }
  }
}
