@import "@sicredi/styles/reset.scss";

html.-integration {
  overflow: hidden;

  body,
  #root {
    overflow: hidden;
  }
}
