@import "../../common/StyleVariables/variables.scss";

#politica-privacidade-root {
  background-color: $privacidade-background-color;
  font-family: $privacidade-title-font-family;
  overflow-x: hidden;
  color: $privicidade-primary-text-color;

  .main-layout {
    max-width: 100vw !important;

    .layout-container {
      max-width: 100% !important;
      margin: 0 15% 0 10%;

      .privacy-policy-header {
        padding: 0 5% 0 10%;

        .up-first-title {
          text-align: center;
          font-weight: 400;
          margin: $baseline 0;
        }

        .first-title {
          font-style: italic;
          font-size: $privacidade-primary-title-font-size;
          font-weight: $privacidade-font-weight;
          text-align: center;
          line-height: 1.6;
        }

        .sub-first-title {
          font-size: $privacidade-paragraph-font-size;
          font-weight: $privacidade-font-weight;
          text-align: center;
          padding: $baseline * 2 0;
        }
      }

      .politica-privacidade-text {
        font-family: $privacidade-paragraph-font-family;
        line-height: 1.5;
        padding-left: 10%;
        padding-right: 5%;
        font-weight: $privacidade-font-weight;
        font-size: $privacidade-paragraph-font-size;

        .sub-title {
          font-family: $privacidade-title-font-family;
          font-size: $privacidade-sub-title-font-size;
          margin-top: $baseline * 4;
          font-weight: $privacidade-font-weight-bold;
        }

        .paragraph {
          font-weight: $privacidade-font-weight;
          font-size: $privacidade-paragraph-font-size;
          text-align: left;
        }

        .list-marker {
          color: $privacidade-primary-green;
        }

        .list-text {
          font-family: $privacidade-title-font-family;
          color: $privicidade-primary-text-color;
        }

        .highlight-green {
          color: $privacidade-primary-green;
        }

        .bold-text {
          font-weight: bolder;
        }

        /* stylelint-disable no-descending-specificity */
        .our-policy-section {
          padding-top: $baseline * 2;

          .subtitle-section {
            font-size: $privacidade-secondary-title-font-size;
            font-weight: 500;
            font-style: italic;
          }

          div .paragraph:first-child {
            margin-top: $baseline * 4;
          }

          div .paragraph {
            margin-bottom: $baseline * 2;
          }
        }

        .what-type-personal-data-section {
          div .paragraph {
            margin-bottom: $baseline * 2;
          }
        }

        .what-processing-personal-data-section,
        .personal-data-children-adolescents-section,
        .who-we-may-share-personal-data-section,
        .how-we-protect-personal-data-section {
          .paragraph {
            margin-bottom: $baseline * 2;
          }
        }

        .anpd-division .paragraph:not(:nth-child(3)),
        .for-more-information-division .paragraph:not(:nth-child(2)) {
          padding-top: $baseline * 2;
        }
      }

      @media (max-width: 512px) {
        .privacy-policy-header {
          .first-title {
            font-size: $privacidade-primary-title-font-size-mobile-512;
          }
        }

        .politica-privacidade-text {
          .our-policy-section {
            .subtitle-section {
              font-size: $privacidade-secondary-title-font-size-mobile-512;
            }
          }
        }
      }

      @media (min-width: 513px) and (max-width: 1024px) {
        .privacy-policy-header {
          .first-title {
            font-size: $privacidade-primary-title-font-size-mobile-1000;
          }
        }

        .politica-privacidade-text {
          .our-policy-section {
            .subtitle-section {
              font-size: $privacidade-secondary-title-font-size-mobile-1024;
            }
          }
        }
      }
    }
  }
}
