@import url("https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,500,500i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Exo+2:200,200i,300,300i,400,400i,500,500i,700,700i");

$baseline: 8px;
$borderRadius: 4px;
// colors
$privacidade-primary-green: #3fa110;
$privicidade-primary-text-color: #323c32;
$privacidade-background-color: #fff;
$privacidade-background-secondary-color: #f8f9f7;

// font family
$privacidade-title-font-family: "Exo 2", "Exo-2", sans-serif;
$privacidade-paragraph-font-family: "Nunito", sans-serif;
$privacidade-font-weight: 400;
$privacidade-font-weight-bold: 800;

// font size
$privacidade-primary-title-font-size: 3rem;
$privacidade-secondary-title-font-size: 2.5rem;
$privacidade-paragraph-font-size: 1rem;
$privacidade-sub-title-font-size: 1rem;

//mobile
$privacidade-primary-title-font-size-mobile-512: 1.5rem;
$privacidade-secondary-title-font-size-mobile-512: 1.2rem;

$privacidade-primary-title-font-size-mobile-1000: 2rem;
$privacidade-secondary-title-font-size-mobile-1024: 1.5rem;

/* stylelint-disable */
:export {
  privacidade-primary-green: $privacidade-primary-green;
  privicidade-primary-text-color: $privicidade-primary-text-color;
  privacidade-title-font-family: $privacidade-title-font-family;
  privacidade-paragraph-font-family: $privacidade-paragraph-font-family;
  privacidade-primary-title-font-size: $privacidade-primary-title-font-size;
  privacidade-secondary-title-font-size: $privacidade-secondary-title-font-size;
  privacidade-paragraph-font-size: $privacidade-paragraph-font-size;
}
