@import "../../common/StyleVariables/variables.scss";

#politica-privacidade-root {
  font-family: $privacidade-title-font-family;

  .dpo-list-section {
    margin: 4vw;
    margin-bottom: $baseline;

    .dpo-list-title {
      font-style: italic;
      font-size: $privacidade-secondary-title-font-size;
      font-weight: $privacidade-font-weight;
      line-height: 1.5;
      margin-bottom: $baseline * 4;
    }

    .dpo-card {
      box-shadow: none;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0;
      outline: 0;

      .dpo-list-accordion {
        h3 {
          color: $privacidade-primary-green;
          font-style: normal;
          font-weight: 500;
          font-family: $privacidade-title-font-family;
          font-size: $privacidade-paragraph-font-size;
          line-height: 1.5;
          padding-left: $baseline * 4;
        }

        button,
        li {
          border: 0;
          overflow: hidden !important;
          outline: 0;
        }

        .paragraph {
          color: $privicidade-primary-text-color;
          margin-bottom: $baseline;
          font-family: $privacidade-paragraph-font-family;
          font-size: $privacidade-paragraph-font-size;
          font-weight: $privacidade-font-weight;
          line-height: 1.5;
          padding-left: $baseline * 4;
        }

        .highlight-green {
          color: $privacidade-primary-green;
        }
      }
    }
  }

  @media (max-width: 512px) {
    .dpo-list-section {
      margin-top: $baseline * 8;
      .dpo-list-title {
        font-size: $privacidade-secondary-title-font-size-mobile-512;
        padding: 0 $baseline * 2;
      }
    }
  }

  @media (min-width: 513px) and (max-width: 1024px) {
    .dpo-list-section {
      margin-top: $baseline * 8;
      .dpo-list-title {
        font-size: $privacidade-secondary-title-font-size-mobile-1024;
        padding: 0 $baseline * 2;
      }
    }
  }
}
